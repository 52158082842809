<template>
    <div class="portfolio-piece">
        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>Logo Design <span>Collection</span></h1>
                    <h1>Logo Design <span>Collection</span></h1>
                </div>
            </div>
        </div>


        <div class="portfolio-cont">

            <div class="logo-sheet">

                <div class="logo-item" v-for="logo in logos" :key="logo.project">
                    <img class="logo-img" :src="logo.link" :alt="logo.project" />
                </div>

            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "PortfolioLogos",
    data() {
        return {
            logos: [
                {
                project: "Etaerio",
                link: require("../assets/images/logos/logo_et.png")
                },
                {
                project: "Wisteria Reader",
                link: require("../assets/images/logos/logo_wist.webp")
                },
                {
                project: "Good Time Charlies",
                link: require("../assets/images/logos/logo_gtc.webp")
                },
                {
                project: "First Edge",
                link: require("../assets/images/logos/logo_fe.webp")
                },
                {
                project: "Full Plate",
                link: require("../assets/images/logos/logo_fp.webp")
                },
                {
                project: "TQ Tran",
                link: require("../assets/images/logos/logo_tqt.webp")
                },
                {
                project: "Kerosene Palace",
                link: require("../assets/images/logos/logo_kp.webp")
                },
                {
                project: "Dr. Banh Mi",
                link: require("../assets/images/logos/logo_dbm.webp")
                },
                {
                project: "NFused",
                link: require("../assets/images/logos/logo_nf.webp")
                },
                {
                project: "Potranco Eye Care",
                link: require("../assets/images/logos/logo_pec.webp")
                },
                {
                project: "Heroes Retreat",
                link: require("../assets/images/logos/logo_hr.webp")
                },
                {
                project: "Allfather Painting",
                link: require("../assets/images/logos/logo_afps.png")
                },
            ]
        }
    },

}
</script>

<style lang="scss">

.logo-sheet {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    .logo-item {
        width: 20%;
        padding: 1em;
        margin: 4em 0;
        transform: scale(1);
        transition: all 0.5s;

        @include respond-to('medium') {
            width: 28%;
            margin: 2em 0;
        }

        @include respond-to('small') {
            width: 90%;
            margin: 1em 0;
        }

        .logo-img {
            max-width: 100%;
        }

        &:hover {
            transform: scale(1.4);
            transition: all 0.5s;
        }
    }
}


</style>